import React from "react";
import "../Intro/Intro.css";

import { Link } from "react-scroll";

const Intro = () => {
  return (
    <section
      className="hero-section set-bg"
      data-setbg="img/hero-bg.jpg"
      id="home"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="hs-text">
              <span>Breakout Artist</span>
              <h2>New Tins</h2>
              <p></p>

              <Link
                className="mint yellowk"
                activeclassname="active"
                spy={true}
                smooth={true}
                to="mintsection"
              >
                Mint
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
