import React, { useEffect } from "react";
import "../About/About.css";
import Aos from "aos";
import "aos/dist/aos.css";
import img from "../../Assets/img/ishiphop.jpg";
const About = () => {
  useEffect(() => {
    Aos.init({
      duration: 2000,
      mirror: true,
    });
    Aos.refresh();
  }, []);

  return (
    <section className="about-us-section spad">
      <div className="container" id="about-me">
        <div className="row">
          <div className="col-lg-6">
            {" "}
            <div className="as-pic">
              <img src={img} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="as-text">
              <div className="section-title">
                <span className="yellowk">About me</span>
                <h2 className="bluek">NewTins x Hip Hop</h2>{" "}
              </div>
              <p className="f-para">
                <span className="quote">"</span>
                New Tins is of a different breed. His knowledge traverses
                generations in a way never seen before. Amidst spectators all
                over the world he has facilitated peace if not inner calmness
                amongst many men and women alike. What makes him special is that
                he is only 18 years old and somehow, he has made grown men
                reconsider the fabric of beauty, music and existence altogether.
                His music, the manifestation of all of his innocent and
                hard-working drives, is something not only to dance to, but to
                live by.<span className="quote">"</span>
                {"_"}
                <a
                  style={{ color: "#fef302" }}
                  href="https://twitter.com/aaxxioomm"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Axm
                </a>
              </p>
              {/*}
              <a href="#" className="primary-btn">
                Read More
              </a>
  --*/}
            </div>
          </div>
        </div>
      </div>
      <div>
        <iframe
          className="videosec"
          src="https://www.youtube.com/embed/otzClUuY_KU"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay=1; loop; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          autoPlay="1"
          loop
          allowFullScreen
        ></iframe>{" "}
        {/*<video
          src={video}
          width="100%"
          autoPlay
          preload="true"
          controls
        ></video>
*/}
      </div>
    </section>
  );
};

export default About;
