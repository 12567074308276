import "../Team/Team.css";

const MeetTheTeam = () => {
  return (
    <main>
      <section
        className="callto-section set-bg"
        data-setbg="img/ctc-bg.jpg"
        id="calltoaction"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="ctc-text">
                <h2>The Break Out Artists</h2>
                <p style={{ color: "yellow" }}>
                  Setting the standard for project development & artist
                  relations.
                </p>
                <a href="https://ba4c.xyz" target={"_blank"} rel="noreferrer">
                  <button className="primary-btn invite">Work With Us</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*
      <section className="member-section spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <span className="yellowk">Our Team</span>
                <h2 className="bluek">Meet The Team</h2>
              </div>
            </div>
          </div>
          <div className="row" id="carousel">
            <OwlCarousel items={3.5} nav margin={8}>
              <div className="col-lg-4 col-md-6">
                <div
                  className="member-item set-bg"
                  data-setbg="img/member/member-1.jpg"
                  id="mem1"
                >
                  <div className="mi-text">
                    <div className="mt-title">
                      <h4>DarknightCrypto</h4>
                      <span>
                        <div className="yellow">BA4C Founder</div>
                        <div className="blue">Creative Director</div>
                        <div className="yellowblue">Visionary</div>
                      </span>
                    </div>
                    <div className="mt-social">
                      <a
                        href="https://soundcloud.com/user-906743551"
                        target={"_blank"}
                      >
                        <i className="fa fa-soundcloud"></i>
                      </a>
                      <a
                        href="https://twitter.com/DarkniteCrypto"
                        target={"_blank"}
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a
                        href="https://instagram.com/DarkniteCrypto"
                        target={"_blank"}
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="member-item set-bg"
                  data-setbg="img/member/member-2.jpg"
                  id="mem2"
                >
                  <div className="mi-text">
                    <div className="mt-title">
                      <h4> Saddie Sadi</h4>
                      <span>
                        <div className="yellow">BA4C Co-Founder </div>
                        <div className="blue"> Artist Director </div>
                        <div className="yellowblue"> Hype Queen</div>
                      </span>
                    </div>
                    <div className="mt-social">
                      <a
                        href="https://twitter.com/saddiesadi"
                        target={"_blank"}
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="member-item set-bg"
                  data-setbg="img/member/member-3.jpg"
                  id="mem3"
                >
                  <div className="mi-text">
                    <div className="mt-title">
                      <h4>Mike✨Booth</h4>
                      <span>
                        <div className="yellow">BA4C Co-Founder </div>
                        <div className="blue"> Community Manager </div>
                        <div className="yellowblue">Graffiti Artist</div>
                      </span>
                    </div>
                    <div className="mt-social">
                      <a
                        href="https://twitter.com/mike_booth"
                        target={"_blank"}
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/mikebooth801/"
                        target={"_blank"}
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                      <a
                        href="https://www.youtube.com/user/Pbrmusic/videos"
                        target={"_blank"}
                      >
                        <i className="fa fa-youtube"></i>
                      </a>
                      <a
                        href="https://www.pbrmusic.xyz/mike-booth"
                        target={"_blank"}
                      >
                        <i className="fa fa-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="member-item set-bg"
                  data-setbg="img/member/member-3.jpg"
                  id="mem4"
                >
                  <div className="mi-text">
                    <div className="mt-title">
                      <h4>Yisrael</h4>
                      <span>
                        <div className="yellow">BA4C Co-Founder </div>
                        <div className="blue"> Lead Developer</div>
                        <div className="yellowblue">Smart Contract Artist</div>
                      </span>
                    </div>
                    <div className="mt-social">
                      <a
                        href="https://twitter.com/gemteplayer1"
                        target={"_blank"}
                      >
                        <i className="fa fa-twitter"></i>
                      </a>

                      <a href="https://gemtexch.com" target={"_blank"}>
                        <i className="fa fa-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
          <div className="row" id="altcaro">
            <div className="col-lg-4 col-md-6">
              <div
                className="member-item set-bg"
                data-setbg="img/member/member-1.jpg"
                id="mem1"
              >
                <div className="mi-tex">
                  <div className="mt-title">
                    <h4>DarknightCrypto</h4>
                    <span>
                      <div className="yellow">BA4C Founder</div>
                      <div className="blue">Creative Director</div>
                      <div className="yellowblue">Visionary</div>
                    </span>
                  </div>
                  <div className="mt-social">
                    <a
                      href="https://soundcloud.com/user-906743551"
                      target={"_blank"}
                    >
                      <i className="fa fa-soundcloud"></i>
                    </a>
                    <a
                      href="https://twitter.com/DarkniteCrypto"
                      target={"_blank"}
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                    <a
                      href="https://instagram.com/DarkniteCrypto"
                      target={"_blank"}
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="member-item set-bg"
                data-setbg="img/member/member-2.jpg"
                id="mem2"
              >
                <div className="mi-tex">
                  <div className="mt-title">
                    <h4> Saddie Sadi</h4>
                    <span>
                      <div className="yellow">BA4C Co-Founder </div>
                      <div className="blue"> Artist Director </div>
                      <div className="yellowblue"> Hype Queen</div>
                    </span>
                  </div>
                  <div className="mt-social">
                    <a href="https://twitter.com/saddiesadi" target={"_blank"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="member-item set-bg"
                data-setbg="img/member/member-3.jpg"
                id="mem3"
              >
                <div className="mi-tex">
                  <div className="mt-title">
                    <h4>Mike✨Booth</h4>
                    <span>
                      <div className="yellow">BA4C Co-Founder </div>
                      <div className="blue"> Community Manager </div>
                      <div className="yellowblue">Graffiti Artist</div>
                    </span>
                  </div>

                  <div className="mt-social">
                    <a href="https://twitter.com/mike_booth" target={"_blank"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/mikebooth801/"
                      target={"_blank"}
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/user/Pbrmusic/videos"
                      target={"_blank"}
                    >
                      <i className="fa fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.pbrmusic.xyz/mike-booth"
                      target={"_blank"}
                    >
                      <i className="fa fa-link"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="member-item set-bg"
                data-setbg="img/member/member-3.jpg"
                id="mem4"
              >
                <div className="mi-tex">
                  <div className="mt-title">
                    <h4>Yisrael</h4>
                    <span>
                      <div className="yellow">BA4C Co-Founder </div>
                      <div className="blue"> Lead Developer</div>
                      <div className="yellowblue">Smart Contract Artist</div>
                    </span>
                  </div>
                  <div className="mt-social">
                    <a
                      href="https://twitter.com/gemteplayer1"
                      target={"_blank"}
                    >
                      <i className="fa fa-twitter"></i>
                    </a>

                    <a href="https://gemtexch.com" target={"_blank"}>
                      <i className="fa fa-link"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  */}
    </main>
  );
};
export default MeetTheTeam;
