import React, { useState } from "react";
import "../Nav/Nav.css";
import Logo from "../../Assets/img/log2.png";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";

const Nav = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const closeMobile = () => {
    setOpen(false);
  };

  const openMenu = <FaBars className="menu" onClick={handleClick} />;

  const closeMenu = <FaTimes className="menu" onClick={handleClick} />;

  return (
    <div className="nav">
      <div className="nav-wrapper">
        <div className="logo">
          <a href="/">
            <img width={120} src={Logo} alt="website logo" />
          </a>
        </div>

        <div className="log">
          <div className="menu" onClick={handleClick}>
            {open ? closeMenu : openMenu}
          </div>

          <div className={open ? "active-links" : "links"}>
            <Link
              activeclassname="active"
              spy={true}
              smooth={true}
              onClick={closeMobile}
              to="project"
            >
              Project
            </Link>
            <Link
              spy={true}
              smooth={true}
              onClick={closeMobile}
              to="about-us-section"
            >
              About
            </Link>
            <Link spy={true} smooth={true} onClick={closeMobile} to="gallery">
              Gallery
            </Link>
            <Link
              spy={true}
              smooth={true}
              onClick={closeMobile}
              to="mintsection"
            >
              Mint
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
