import "./Tracks.css";
import icon from "../../Assets/img//blog/author-1.jpg";

const Tracks = () => {
  return (
    <div className="blog-section latest-blog spad">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <h2 className="yellowk">TRACKS</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            {" "}
            <div className="blog-item">
              <div className="row">
                <div className="col-lg-6">
                  <div className="bi-pic set-bg" id="prt1">
                    <iframe
                      style={{ borderRadius: "12px" }}
                      src="https://open.spotify.com/embed/track/40XIK7Jey0kqTwe170zn3o?utm_source=generator"
                      width="100%"
                      height="152"
                      frameBorder="0"
                      title="No More Me Preview"
                      allowFullScreen=""
                      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                      loading="lazy"
                      as="font"
                    ></iframe>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="bi-text">
                    <ul>
                      <li>
                        <i className="fa fa-calendar-o blue"></i>
                        <span className="yellowk">August 13, 2022</span>
                      </li>
                    </ul>
                    <span className="blue">Track 1</span>
                    <h4>
                      <a
                        href="https://distrokid.com/hyperfollow/newtins/no-more-me"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        No More Me
                      </a>
                    </h4>

                    <div className="bt-author">
                      <div className="ba-pic">
                        <img src={icon} alt="" />
                      </div>
                      <div className="ba-text">
                        <h5>NewTins</h5>
                        <i className="blue">Rapper</i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="blog-item">
              <div className="row">
                <div className="col-lg-6">
                  <div
                    className="bi-pic set-bg"
                    data-setbg="img/blog/blog-2.jpg"
                    id="prt2"
                  >
                    {" "}
                    <iframe
                      style={{ borderRadius: "12px" }}
                      src="https://open.spotify.com/embed/album/3QTJlZuTpQwkx3ZIWeHadc?utm_source=generator"
                      width="100%"
                      height="152"
                      title="Drip Preview"
                      frameBorder="0"
                      allowFullScreen=""
                      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                      as="font"
                    ></iframe>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="bi-text">
                    <ul>
                      <li>
                        <i className="fa fa-calendar-o blue"></i>{" "}
                        <span className="yellowk">August 14, 2022</span>
                      </li>
                    </ul>
                    <span className="blue">Track 2</span>
                    <h4>
                      <a
                        href="https://distrokid.com/hyperfollow/newtins/drip"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Drip Freestyle
                      </a>
                    </h4>

                    <div className="bt-author">
                      <div className="ba-pic">
                        <img src={icon} alt="" />
                      </div>
                      <div className="ba-text">
                        <h5>NewTins</h5>
                        <i className="blue">Rapper</i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Tracks;
