import "../MintSection/Mintsec.css";
import React, { useState } from "react";
import META from "../../Assets/img/metamask.svg";
import "aos/dist/aos.css";
import eth from "../../Assets/img/chains/eth.png";
import cronos from "../../Assets/img/chains/cronos.png";
import tez from "../../Assets/img/chains/tez.png";
import { init, MINT } from "../../Web3Client";
import { connectWalletTez, mintEdition } from "../../tezosclient copy";

const MintSec = () => {
  const [walletconnectedETH, setWalletConnectedETH] = useState(false);
  const [buyingwithCard, setBuyingWithCard] = useState(false);
  const [purchaseChain, setPurchaseChain] = useState("none");
  const [walletconnectedCRO, setWalletConnectedCRO] = useState(false);
  const [walletconnectedTZ, setWalletConnectedTZ] = useState(false);
  const [TXN, setTXN] = useState("0x0");

  const initialState = {
    number: "1",
  };
  const [state, setState] = useState(initialState);
  const [openmodal, setOpenModal] = useState(false);
  const [tokenmintSuccess, setTokenMintSuccess] = useState(false);
  const [explorer, setExplorer] = useState("");
  const [mintPrice, setMintPrice] = useState("0.01");
  const { number } = state;
  //  () => JSON.parse(localStorage.getItem("chain")) || "none"
  //);
  //useEffect(() => {
  //  localStorage.setItem("chain", JSON.stringify(walletconnected));
  //}, ["chain", walletconnected]);

  //const connectedChain = Number(localStorage.getItem("chain"));

  const MMModal = () => {
    return (
      <div className="overlay">
        <div className="ovmodal">
          <div className="ovmodal-contents">
            <div className="ovhead">
              <h5>Connect Wallet</h5>{" "}
              <p onClick={handleCloseModal}>
                {" "}
                <i className="fa fa-window-close"></i>
              </p>
            </div>

            <div>
              <div className="ovwallet" onClick={() => connectWallet("eth")}>
                <img width={80} src={META} alt="metamask" />
                <p>Metamask</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleOpenModal = (chain) => {
    setOpenModal(true);
    setPurchaseChain(chain);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const connectWallet = async () => {
    try {
      setWalletConnectedETH(false);
      setWalletConnectedCRO(false);
      setWalletConnectedTZ(false);

      switch (purchaseChain) {
        case "eth":
          await init("eth");
          setWalletConnectedETH(true);
          setMintPrice("0.01");
          setExplorer("https://etherscan.io/tx/");
          break;
        case "cronos":
          await init("cronos");
          setWalletConnectedCRO(true);
          setMintPrice("200");
          setExplorer("https://cronoscan.com/tx/");
          break;
        default:
          break;
      }
      //window.location.reload();
      handleCloseModal();
    } catch (error) {
      console.log(error);
      //      alert("Could not connect wallet");
    }
  };

  const mint = async (chain) => {
    const hash = await MINT(number, mintPrice * number, chain);
    setTXN(hash);
    setTokenMintSuccess(true);
  };

  return (
    <section className="services-section spad mintsection">
      {!tokenmintSuccess ? (
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <span className="yellowk">Mint My Songs</span>
              </div>
            </div>
          </div>
          <div className="row mint-container">
            <div className="slice">
              <div className="innerslice one"></div>
              <div>
                <div className="action">
                  {!walletconnectedETH ? (
                    <div>
                      {openmodal ? (
                        <MMModal />
                      ) : (
                        <button
                          className="primary-btn"
                          onClick={() => {
                            handleOpenModal("eth");
                          }}
                        >
                          Connect Wallet
                          <img className="blockicon" src={eth} alt="" />
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className="mintbutton-value">
                      <div>
                        <div className="butt-val">
                          <button onClick={mint}>Mint</button>
                          <input
                            className="edit-wrapper"
                            type="number"
                            name="number"
                            step="1"
                            value={number}
                            onChange={handleChange}
                            min={1}
                            max={20}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="slice">
              <div className="innerslice two"></div>
              <div>
                <div className="action">
                  {!walletconnectedCRO ? (
                    <div>
                      {openmodal ? (
                        <MMModal />
                      ) : (
                        <button
                          className="primary-btn"
                          onClick={() => {
                            handleOpenModal("cronos");
                          }}
                        >
                          Connect Wallet
                          <img className="blockicon" src={cronos} alt="" />
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className="mintbutton-value">
                      <div>
                        <div className="butt-val">
                          <button onClick={() => mint("cronos")}>Mint</button>
                          <input
                            className="edit-wrapper"
                            type="number"
                            name="number"
                            step="1"
                            value={number}
                            onChange={handleChange}
                            min={1}
                            max={20}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="slice">
              <div className="innerslice three"></div>
              <div>
                <div className="action">
                  {!walletconnectedTZ ? (
                    <div>
                      {openmodal ? (
                        <MMModal />
                      ) : (
                        <button
                          className="primary-btn"
                          onClick={() => {
                            connectWalletTez()
                              .then((data) =>
                                setWalletConnectedTZ(data.success)
                              )
                              .then(setExplorer("https://tzkt.io/tx/"));
                          }}
                        >
                          Connect Wallet
                          <img className="blockicon" src={tez} alt="" />
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className="mintbutton-value">
                      <div>
                        <div className="butt-val" id="tez-btns">
                          <button onClick={() => mintEdition(1)}>Song 1</button>
                          <button onClick={() => mintEdition(2)}>Song 2</button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="overlay">
          <div className="tokenmint">
            <img width={300} src={META} alt="" />
            <div className="head">Mint Successful</div>

            <div className="contents">
              <button className="explorer">
                <a
                  href="https://foundation.com/BottomPreSale"
                  rel="noopener noreferrer"
                >
                  View your NFT
                </a>
              </button>
              <button className="explorer">
                <a
                  href={`${explorer}${TXN}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Transaction on Explorer
                </a>
              </button>

              <button
                className="close"
                onClick={() => setTokenMintSuccess(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="container">
        <div className="row">
          <div className="col-lg-10 m-auto">
            <div className="ctc-text">
              <h2>Buy with Credit Card</h2>
              {!buyingwithCard ? (
                <div>
                  <button
                    className="primary-btn invite"
                    onClick={() => setBuyingWithCard(true)}
                  >
                    Buy
                  </button>
                </div>
              ) : (
                <div className="overlay ovcard">
                  <button onClick={() => setBuyingWithCard(false)}>X</button>
                  <iframe
                    title="NFTPay"
                    style={{
                      border: "none",
                      height: "80vh",
                    }}
                    src="https://payments.nftpay.xyz/iframe/iframe_pay/1326d90d-ad4f-4834-8e6c-7c336d3d18b8?"
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MintSec;
