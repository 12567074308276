import React from "react";
import About from "./Components/About/About";
import Footer from "./Components/Footer/Footer";
import Intro from "./Components/Intro/Intro";
import MintSec from "./Components/MintSection/MintSec";
import Nav from "./Components/Nav/Nav";
import Gallery from "./Components/Gallery/Gallery";
import Reference from "./Components/Reference/Reference";
import MeetTheTeam from "./Components/Team/Team";
import Tracks from "./Components/Tracks/Tracks";
import Project from "./Components/Project/Project";

function App() {
  return (
    <div className="App">
      <Nav />
      <div className="contents">
        <Intro />
        <About />
        <Project />
        <MintSec />
        <Gallery />
        <Reference />
        <MeetTheTeam />
        <Tracks />
        <Footer />
      </div>
    </div>
  );
}

export default App;
