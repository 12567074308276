import React from "react";
import "../Styles/bootstrap.min.css";
import "../Styles/font-awesome.min.css";
import "../Styles/themify-icons.css";
import "../Styles/magnific-popup.css";
import "../Styles/slicknav.min.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import "owl.carousel/dist/owl.carousel.min.js";
import "owl.carousel/dist/owl.carousel.js";
import "../Reference/Reference.css";
import img1 from "../../Assets/img/testimonial/img1.png";
import img2 from "../../Assets/img/testimonial/img2.png";
import img4 from "../../Assets/img/testimonial/img4.png";

const Reference = () => {
  return (
    <section className="testimonial-section spad">
      <div className="container">
        <div className="row testimonial-slider">
          <OwlCarousel items={1.2} className="owl-theme" loop nav margin={8}>
            <div className="col-lg-6">
              <div className="testimonial-item ">
                <div className="ti-pic">
                  <img src={img1} alt="" />
                </div>
                <div className="ti-text">
                  <div className="ti-title">
                    <a
                      href="https://twitter.com/Portmidas"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <h4>Midas.ngmi.acgi</h4>
                    </a>
                  </div>
                </div>{" "}
                <p>
                  “New Tins is indescribable as an artist. In unison with
                  instrumentals, New Tins does not simply compliment the track;
                  he is a lyrical beacon of light that beams through it. Not
                  subjected by any particular genre, New Tins outperforms most
                  artists whether drilling cadence or heartfelt prose.”
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="testimonial-item">
                <div className="ti-pic">
                  <img src={img2} alt="" />
                </div>
                <div className="ti-text">
                  <div className="ti-title">
                    <a
                      href="https://www.pbrmusic.xyz/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <h4>Mike✨Booth</h4>
                    </a>
                  </div>
                </div>{" "}
                <p>
                  One way i can tell if some one has “IT” is by the way they
                  create , when everyone else would have packed it up and called
                  it quits. New Tins has “IT”. What he does with what he has is
                  the tip of the iceberg, underneath is so much more. He brings
                  the pulse of the streets with colorful wordplay, and the
                  vulnerability of growth paired with the pain of being a
                  struggling young man. Somehow, the struggle disappears, and
                  then your left with a sprinkled gem served with a gracious
                  slice of beautiful poetry. His bars provide a pleasant after
                  taste that keeps your mouth watering for his next serving.
                  Take a listen, but grab a napkin before you do.
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="testimonial-item">
                <div className="ti-pic">
                  <img src={img4} alt="" />
                </div>
                <div className="ti-text">
                  <div className="ti-title">
                    <a
                      href="https://twitter.com/saddiesadi"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <h4>Sadi Sadi</h4>
                    </a>
                  </div>
                </div>{" "}
                <p className="content">
                  New tins is an amazing artist the way he performs that’s what
                  people amazed! He has a great energy with talent and kills
                  ..He can play with any kinda beat non stop ..wonderfully soul
                  just wana say keep up good work …Music industry needs a great
                  entertainers like you …New tine is a next super Music artist .
                </p>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </section>
    /*}

    <div className="col-lg-6" style={{ display: "none" }}>
              <div className="testimonial-item">
                <div className="ti-pic">
                  <img src={img3} alt="" />
                </div>
                <div className="ti-text">
                  <div className="ti-title">
                    <a href="http://supotsudynasty.com/" target={"_blank"}>
                      <h4>GraeWulf.eth</h4>{" "}
                    </a>
                  </div>
                  <p>
                    New tins is an amazing artist the way he performs that’s
                    what people amazed! He has a great energy with talent and
                    kills ..He can play with any kinda beat non stop
                    ..wonderfully soul just wana say keep up good work …Music
                    industry needs a great entertainers like you …New tine is a
                    next super Music artist .
                  </p>
                </div>
              </div>
            </div>

    <section className="testimonial-section spad">
      <div className="container">
        <div className="row testimonial-slider" classNamse="owl-carousel">
          <div className="col-lg-6">
            <div className="testimonial-item">
              <div className="ti-pic">
                <img src={img2} alt="" />
              </div>
              <div className="ti-text">
                <div className="ti-title">
                  <h4>Saddie</h4>
                  <span>Breakout Artist</span>
                </div>
                <p>NewTins is a good rapper</p>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="testimonial-item">
              <div className="ti-pic">
                <img src={img4} alt="" />
              </div>
              <div className="ti-text">
                <div className="ti-title">
                  <h4>Mike</h4>
                  <span>Breakout Artist</span>
                </div>
                <p>NewTins is a good rapper</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    */
  );
};
export default Reference;
