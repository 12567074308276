import React, { useEffect } from "react";
import "../Project/Project.css";
import Aos from "aos";
import "aos/dist/aos.css";

const Project = () => {
  useEffect(() => {
    Aos.init({
      duration: 2000,
      mirror: true,
    });
    Aos.refresh();
  }, []);

  return (
    <div className="project">
      <div className="about-cont">
        <div className="head yellowk">The Project</div>
        <div className="box" data-aos="fade-up">
          <div className="details">
            <div>
              Buy <span className="neon">New Tins </span> E.P.{" "}
              <span className="neon">"Bottom" </span> at the reduced price of
              <span className="neon"> 200 CRO or .01 ETH </span> + gas
            </div>
            <div>
              <span className="italic">
                {" "}
                When you purchase one of his singles;
              </span>{" "}
              <span className="neon">"Drip"</span> on{" "}
              <span className="neon">Cronos</span> or{" "}
              <span className="neon">"No More Me"</span> on{" "}
              <span className="neon">Ethereum</span>, you will receive the{" "}
              <span className="neon">"Bottom" EP</span> before you can buy it!
            </div>
            <h3 className="yellowk">O.G. Supporters </h3>
            <div>
              For those of you who want to support this{" "}
              <span className="neon">AMAZING Artist</span> in a{" "}
              <span className="neon">BIG</span> way, purchase{" "}
              <span className="neon">both singles</span>, you will be airdropped
              the <span className="neon">Deluxe E.P. </span> which will include{" "}
              <span className="neon">"True Love"</span> a track written by{" "}
              <span className="neon">New Tins for his friends</span> +{" "}
              <span className="neon">
                exclusive digital collectable memorabilia!
              </span>
            </div>
            <div>
              <span className="reduced">
                NFT singles will be open editions until "E.P." drops then the
                collection will be closed adding{" "}
                <span className="neon">
                  value and scarcity to existing holders!
                </span>{" "}
                Exclusive content will not be minted or available in the future
                as an NFT, the <span className="neon">ONLY</span> way to get
                this content is to mint Both singles while contract is OPEN.
                <span className="neon">"True Love"</span> will not be
                distributed on streaming platforms for min of 6 months from the
                drop of the E.P., granting{" "}
                <span className="neon">O.G. Supporters</span> the chance to{" "}
                <span className="neon">Listen to it FIRST!</span>
              </span>
            </div>
            <h3 className="yellowk">Ultimate VIP Supporter</h3>
            <div>
              <span className="neon">
                {" "}
                2x 1/1 NFT Gold Edition SIngles on Tezos X 100xtz <br />
                Airdropped "Bottom" E.P Deluxe including "True Love" <br />{" "}
                24HRS Early Release
                <br /> Holders are Airdropped the "Bottom" E.P Deluxe, including
                the single "True Love" First! 24HRS before official release!+1
                Free Mint on ALL future Music NFT Drops from New Tins!
                +exclusive digital collectible memorabilia
                <br />
              </span>
              <span className="yellowk">WHAT ARE YOU WAITING FOR?</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
