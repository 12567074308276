import logo from "../../Assets/img/logo.png";
import "./Footer.css";

const Footer = () => {
  return (
    <section className="footer-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="footer-option">
              <div className="fo-logo">
                <a href="/">
                  <img src={logo} alt="" />
                </a>
              </div>
              <ul>
                <li>Email: support@ba4c.xyz</li>
              </ul>
              <div className="fo-social">
                <a
                  href="https://youtube.com/channel/UCmD51kzZ0_SD_udOyOmUXTg"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <i className="fa fa-youtube"></i>
                </a>
                <a href="https://ba4c.xyz" target={"_blank"} rel="noreferrer">
                  <i className="fa fa-link"></i>
                </a>
                <a
                  href="https://twitter.com/DarkniteCrypto"
                  rel="noreferrer"
                  referrerPolicy={"origin"}
                  target={"_blank"}
                >
                  <i className="fa fa-twitter"></i>
                </a>
                <a
                  href="https://soundcloud.com/user-906743551"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <i className="fa fa-soundcloud"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footer-widget">
              <h5>Join The Newsletter</h5>
              <p>Get E-mail updates about our latest Artists.</p>
              <form action="#" className="news-form">
                <input type="text" placeholder="Enter your mail" />
                <button type="submit">Subscribe</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
