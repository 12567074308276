// import NFTContractBuild from 'contracts/NFT.json';
import Web3 from "web3";

let selectedAccount;

let isInitialized = false;

const contractAbi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_mintAmount",
        type: "uint256",
      },
    ],
    name: "mint",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
];

let provider = window.ethereum;

export const web3 = new Web3(provider);

export const init = async (chain) => {
  let provider = window.ethereum;
  provider
    .request({ method: "eth_requestAccounts" })
    .then((accounts) => {
      selectedAccount = accounts[0];
      console.log(`${selectedAccount} is connected`);
    })
    .catch((err) => {
      console.log(err);
      return;
    });

  window.ethereum.on("accountsChanged", function (accounts) {
    selectedAccount = accounts[0];
    console.log(`Selected account changed to ${selectedAccount}`);
  });

  const currentChainId = await window.ethereum.request({
    method: "eth_chainId",
  });

  console.log(`Chained to ${currentChainId}`);
  console.log(`Connecting to ${chain}`);
  switch (chain) {
    case "eth":
      if (currentChainId !== "0x1") {
        await switchNetwork("eth");
      }
      break;
    case "cronos":
      if (currentChainId !== "0x19") {
        await switchNetwork("cronos");
      }
      break;

    default:
      break;
  }

  isInitialized = true;
  return selectedAccount;
};

// switches network to the one provided
export const switchNetwork = async (chain) => {
  switch (chain) {
    case "eth":
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x1" }],
      });
      break;
    case "cronos":
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x19",
            chainName: "Cronos Mainnet Beta",
            nativeCurrency: {
              name: "Cronos",
              symbol: "CRO",
              decimals: 18,
            },
            rpcUrls: ["https://evm.cronos.org"],
            blockExplorerUrls: ["https://cronoscan.com"],
          },
        ],
      });
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x19" }],
      });
      break;
    case "tezos":
      // execute case y code block
      break;
    default:
      break;
  }
};

export const MINT = async (quantity, amount, chain) => {
  let hash;
  if (!isInitialized) {
    await init(chain);
  }
  const Contract = new web3.eth.Contract(
    contractAbi,
    "0x1AcCE5032f2ad672Bf2B23d87138Ce96AbaefE95"
  );

  await Contract.methods
    .mint(quantity)
    .send({
      from: selectedAccount,
      gas: 100000,
      value: web3.utils.toWei(String(amount), "ether"),
    })
    .on("receipt", (receipt) => {
      hash = receipt.transactionHash;
    });
  return hash;
};
