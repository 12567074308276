import { TezosToolkit } from "@taquito/taquito";
import { BeaconWallet } from "@taquito/beacon-wallet";

const config = {
  contractAddress: "KT1G12fxWBgVL4ts6bbYoH72hx6Au975UPdT",
};

const preferredNetwork = "mainnet";
const options = {
  name: "GemTexch",
  iconUrl: "https://gemtexch.com/apple-touch-icon.png",
  preferredNetwork: preferredNetwork,
};
const rpcURL = "https://mainnet.api.tez.ie";
const wallet = new BeaconWallet(options);

const getActiveAccount = async () => {
  return await wallet.client.getActiveAccount();
};

const connectWalletTez = async () => {
  let account = await wallet.client.getActiveAccount();
  if (!account) {
    await wallet.requestPermissions({
      network: { type: preferredNetwork },
    });
    account = await wallet.client.getActiveAccount();
  }
  return { success: true, wallet: account.address };
};

const disconnectWallet = async () => {
  await wallet.disconnect();
  return { success: true, wallet: null };
};

const checkIfWalletConnected = async (wallet) => {
  try {
    const activeAccount = await wallet.client.getActiveAccount();
    if (!activeAccount) {
      await wallet.client.requestPermissions({
        type: { network: preferredNetwork },
      });
    }
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

const mintEdition = async (edition) => {
  console.log("Tezing");
  try {
    const response = await checkIfWalletConnected(wallet);
    if (response.success) {
      const tezos = new TezosToolkit(rpcURL);
      tezos.setWalletProvider(wallet);
      const contract = await tezos.wallet.at(config.contractAddress);
      let operation;
      switch (edition) {
        case 1:
          operation = await contract.methods
            .mint1("unit")
            .send({ amount: 100 });
          break;
        case 2:
          operation = await contract.methods
            .mint2("unit")
            .send({ amount: 100 });
          break;
        default:
          alert("Something went wrong");
          break;
      }
      const result = await operation.confirmation();
      console.log(result);
    }
  } catch (err) {
    console.log(err);
  }
};

export { connectWalletTez, disconnectWallet, getActiveAccount, mintEdition };
