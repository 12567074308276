import "./Gallery.css";

const Gallery = () => {
  return (
    <main>
      <section className="portfolio-section spad " id="gallery">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title yellowk">
                <span>NewTins</span>
                <h2 className="bluek">Hip Hop is my game</h2>
              </div>
            </div>
          </div>
          <div className="row spin">
            <div className="col-lg-6">
              <div
                className="portfolio-item set-bg large-item"
                id="port1"
              ></div>
            </div>
            <div className="col-lg-6 ">
              <div className="row mobileflex" id="mobileshow">
                <div className="col-md-6">
                  <div className="portfolio-item set-bg" id="port2"></div>
                </div>
                <div className="col-md-6">
                  <div className="portfolio-item set-bg" id="port3"></div>
                </div>
              </div>
              <div className="row  ">
                <div className="col-md-6">
                  <div className="portfolio-item set-bg" id="port4"></div>
                </div>
                <div className="col-md-6">
                  <div className="portfolio-item set-bg" id="port5"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Gallery;
